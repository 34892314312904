<template>
    <div class="pb-10 h-100 ml-2">
        <div class="d-flex flex-row flex-wrap align-items-center justify-content-between">
            <div class="breadcrumbactive">
                <div >
                    <span v-if="loggedInUser.user_has == 'organization'" >Manage Organization </span>
                    <span v-else-if="loggedInUser.user_has == 'companies'" >Manage Companies </span>
                    <div>List View</div>
                </div>
            </div>

            <div class=" d-flex align-items-center ml-3" style="position: relative;width: 35% !important;">
                <i class="icon icon-magnify fs-15" style="position: absolute; left: 10px;"></i>
                <i v-if="search_key !=''" class="icon icon-close fs-20" style="position: absolute; right: 10px;color: #707070 !important;cursor: pointer;" @click="clearSearch"></i>
                <input type="text" class="search-input-field" v-model="search_key" v-debounce:500ms="getListViewData" debounce-events="input" placeholder="Search by Organization Name, Company Name, Admin Name and Super Admin name " />
            </div>
            <div class="d-flex flex-wrap align-items-center mr-2">
                <button v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'admin' ||  loggedInUser.user_role == 'employee'" type="button" @click="$router.push('/linkemp/create')" class="btn btn-new-primary ml-3 px-3 py-1 button-padding" style="border-radius: 6px !important;width: 130px !important;">
                    Link Employee
                </button>
                <button v-if="loggedInUser.user_role == 'owner'" type="button" @click="showDeleteOrg" :disabled="org_selected.length <= 0" class="btn btn-smm btn-danger ml-3 button-padding" style="border-radius: 6px !important;">
                    <i class="icon icon-minus fs-12 text-white"></i>
                    Delete
                </button>
                <button v-if="loggedInUser.user_role == 'owner'" type="button" @click="$router.push('/organization/create')" class="btn btn-smm text-white btn-success ml-3 button-padding" style="border-radius: 6px !important;width: 185px;">
                    <i class="icon icon-plus fs-12 text-white" style="color: #fff !important;"></i>
                    Create Organization
                </button>
            </div>
        </div>
        <div class="pr-2">
            <div class="w-100">
                <!-- <div class="d-flex align-items-center" > -->
                    <!-- <div class="d-flex align-items-center">
                        <div class="header-slant-tab">
                            <span class="text-secondary fs-14">List View</span>
                        </div>
                        <div class="header-slant-tab-border"></div>
                    </div>
                    <div class="ml-3 d-flex align-items-center w-100" style="position: relative;">
                        <i class="icon icon-magnify fs-15" style="position: absolute; left: 10px;"></i>
                        <input type="text" class="search-input-field" v-model="search_key" v-debounce:500ms="getListViewData" debounce-events="input" placeholder="Search by Organization Name, Company Name, Admin Name and Super Admin name " />
                    </div> -->
                    <!-- <div class="d-flex justify-content-center ml-2" style="background: rgb(255, 255, 255); border-radius: 20px; width: 150px; height: 2.9em;">
                        <div class="d-flex justify-content-center align-items-center pointer pointer-events-none" style="padding: 0 10px; border-right: 0.5px solid rgb(204, 227, 252); opacity: 0.5;">
                            <img src="/static/images/grid_new.svg" width="18">
                        </div>
                        <div class="d-flex justify-content-center align-items-center pointer" style="padding: 0 10px; border-left: 0.5px solid rgb(204, 227, 252);">
                            <img src="/static/images/filter_bars_new.svg" width="18">
                        </div>
                    </div> -->
                <!-- </div> -->
                <div class="table-start" style="min-height: 30em;">
                    <table class="admin-new-table w-100" style="border-collapse: collapse; position: relative;" width="100%">
                        <thead>
                            <tr>
                                <th class="text-center">
                                    <input v-if="loggedInUser.user_role == 'owner'" type="checkbox" v-model="check_all" @change="checkAll" name="select_all" />
                                </th>
                                <th style="padding-left: 0px !important;"></th>
                                <th>
                                    Organization Name
                                </th>
                                <th></th>
                                <th>
                                    Created On
                                </th>
                                <th></th>
                                <th>
                                    Company name
                                </th>
                                <th>
                                    Super Admin
                                </th>
                                <th>
                                    Admin
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="ajax_call_in_progress">
                                <td colspan="8">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="!ajax_call_in_progress && list_view_data.length == 0" class="text-center font-weight-bold">
                                <td colspan="8">No Organizations Found</td>
                            </tr>
                            <template v-else v-for="(org, index) in list_view_data">
                                <tr :key="index+'kjhh'" style="border: 1px solid #ecf1f9; margin-bottom: 3px;">
                                    <td class="text-center">
                                        <input v-if="loggedInUser.user_role == 'owner'" type="checkbox" v-model="org.checked" @change="selectOrg($event, org.id, index)" name="select_one" />
                                    </td>
                                    <td class="text-center">
                                        <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                                            <button v-if="loggedInUser.user_role == 'owner'" class="pointer btn-icon pt-3" @click="editOrganization(org.id)" style="padding:0px!important;">
                                                <img src="/static/images/edit-new.svg" height="15px;">
                                            </button>
                                        </el-tooltip>
                                    </td>
                                    <td>
                                        <div v-if="org.company_list" class="pointer" @click="showAllCompanies(index)" >
                                            <span>{{org.organization_name || "-"}}</span>
                                            <i v-if="org.company_list.length > 0" class="icon vertical-align-middle ml-3" :class="{'icon-chevron-up':org.show, 'icon-chevron-down':!org.show}" style="color: #3f3f3f;"></i>
                                        </div>
                                        <div v-else :class="{'background-color-company': loggedInUser.user_role == 'owner'}">
                                            <span>{{org.organization_name || "-"}}</span>
                                        </div>
                                    </td>
                                    <td></td>
                                    <td>{{moment(org.created_on).format("DD/MM/YYYY") || "-"}}</td>
                                    <td></td>
                                    <td v-if="org.company_list">
                                            <span v-if="org.company_list.length > 0">
                                                {{org.company_list[0].company_name || "-"}}
                                                <span v-if="org.company_list.length > 1" class="text-secondary fs-12 font-weight-bold"></span>
                                            </span>
                                            <span v-else>
                                                {{"-"}}
                                            </span>
                                        <el-popover v-if="org.company_list.length > 1" placement="bottom" width="200" trigger="hover">
                                            <div v-for="(org,index) in org.company_list" :key="index">
                                                <span class="fw-500">{{org.company_name}}</span>
                                            </div>
                                            <el-button slot="reference" class="pl-0 pr-0 button-back">
                                                <div class="fw-500 mt-4px text-center text-secondary">
                                                    +{{org.company_list.length - 1}} more
                                                </div>
                                            </el-button>
                                        </el-popover>

                                    </td>
                                    <td v-else>
                                        {{"-"}}
                                    </td>
                                    <td>
                                        <span v-if="org.super_admin.length > 0">
                                            {{org.super_admin[0] || "-"}}
                                            <span v-if="org.super_admin.length > 1" class="text-secondary fs-12 font-weight-bold">+{{org.super_admin.length-1}} More</span>
                                        </span>
                                        <span v-else>
                                            -
                                        </span>
                                    </td>
                                    <td>
                                        <span v-if="org.admin.length > 0">
                                            {{org.admin[0] || "-"}}
                                            <span v-if="org.admin.length > 1" class="text-secondary fs-12 font-weight-bold">+{{org.admin.length-1}} More</span>
                                            <!-- <span v-if="org.admin.length > 0 && (org.admin.length - 1 != new_index)">,</span> -->
                                        </span>
                                        <span v-else>
                                            -
                                        </span>
                                    </td>
                                </tr>
                                <template v-for="(company, comp_index) in org.company_list">
                                    <tr :key="comp_index+'ll'" v-if="org.show" class="background-color-company" :class="{'margin-company': loggedInUser.user_role == 'owner'}">
                                        <td class="text-center">
                                        </td>
                                        <td>

                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td>{{moment(company.created_on).format("DD/MM/YYYY") || "-"}}</td>
                                        <td></td>
                                        <!-- <td v-if="loggedInUser.user_role == 'owner'" class="text-secondary pointer" @click="goToCompanyView(company.company_id)"> -->
                                        <td class="text-secondary pointer" @click="goToCompanyView(company.company_id, org.organization_name)">
                                            {{company.company_name || "-"}}
                                        </td>
                                        <!-- <td v-else-if="(loggedInUser.user_role == 'super_admin' || loggedInUser.user_role == 'super admin') && (loggedInUser.company_id == company.company_id)" class="text-secondary pointer" @click="goToCompanyView(company.company_id)">
                                            {{company.company_name || "-"}}
                                        </td>
                                        <td v-else class="pointer">
                                            {{company.company_name || "-"}}
                                        </td> -->
                                        <td>
                                            <span v-if="company.super_admin.length > 0">
                                                {{company.super_admin[0] || "-"}}
                                                <span v-if="company.super_admin.length > 1" class="text-secondary fs-12 font-weight-bold">+{{company.super_admin.length-1}} More</span>
                                            </span>
                                            <span v-else>
                                                -
                                            </span>
                                        </td>
                                        <td>
                                            <span v-if="company.admin.length > 0">
                                                {{company.admin[0] || "-"}}
                                                <span v-if="company.admin.length > 1" class="text-secondary fs-12 font-weight-bold">+{{company.admin.length-1}} More</span>
                                            </span>
                                            <span v-else>
                                                -
                                            </span>
                                        </td>
                                        <td>
                                            <span v-if="loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin'" class="pointer" @click="showDeleteCompany(company.company_id)">
                                                <el-tooltip class="item" effect="dark" content="Delete" placement="top">
                                                    <i class="icon icon-delete text-secondary" style="color: #f05050 !important;"></i>
                                                </el-tooltip>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr :key="comp_index+'createl'" v-if="org.show && (comp_index == org.company_list.length-1) && (loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin')">
                                        <td colspan="10" class="text-right pr-5" style="border: 0 !important;">
                                            <span @click="createOrgCompany(org.id,org.organization_name)" class="fs-13 text-secondary pointer" style="background-color:  #00448b !important; color: #fff !important;padding: 5px;border-radius: 5px;">
                                                + Create New Company
                                            </span>
                                        </td>
                                    </tr>
                                </template>
                                <tr :key="index+'orgkg'" v-if="org.company_list" >
                                    <td v-if="org.company_list.length === 0 && (loggedInUser.user_role == 'owner' || loggedInUser.user_role == 'super admin' || loggedInUser.user_role == 'super_admin')" colspan="10" class="text-right pr-5" style="border: 0 !important;">
                                        <span @click="createOrgCompany(org.id,org.organization_name)" class="fs-13 text-secondary pointer" style="background-color:  #00448b !important; color: #fff !important;padding: 5px;border-radius: 5px;">
                                            + Create New Company
                                        </span>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
                <div v-if="!ajax_call_in_progress" class="d-flex justify-content-end align-items-center bg-white py-2 px-4" style="border-bottom-left-radius: 5px; border-bottom-right-radius: 5px">
                    <multiselect class="diginew-multiselect"
                        :options="pageLimit" :close-on-select="true" v-model.number="limit"
                        open-direction="top" :show-labels="false" placeholder=""
                        style="width: 4.0em !important;padding-right: 10px!important;padding-top: 10px !important;"></multiselect>
                    <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/static/images/images/down-arrow-grey.svg" style="margin-left: -20px;">
                    <span class="page-info pr-2 pl-6 fw-500" style="height: 2.4rem;line-height: 2.4rem;color: #303031;font-size: 14px;">Per page</span>
                    <button @click="firstPage()" :class="{'not-allowed' : skip == 0}" :disabled="skip == 0" class="mt-1 pointer pagination-list">
                        <el-tooltip class="item" effect="dark" content="First Page" placement="top">
                            <i class="icon-chevron-double-left"></i>
                        </el-tooltip>
                    </button>
                    <button @click="previousPage()" :class="{'not-allowed' : skip == 0}" :disabled="skip == 0" class="pagination-list mt-1 ml-2 pointer">
                        <el-tooltip class="item" effect="dark" content="Previous Page" placement="top">
                            <i class="icon icon-chevron-left"></i>
                        </el-tooltip>
                    </button>
                    <span class="px-2 fw-500 fs-14 mt-1">Showing {{getSkipCount}} - {{total>skip+limit?skip+limit:total}} of {{total}}</span>
                    <button @click="nextPage()" :class="{'not-allowed' : skip_temp >= total }" :disabled="skip_temp >= total" class="pagination-list mt-1 mr-2 pointer">
                        <el-tooltip class="item" effect="dark" content="Next Page" placement="top">
                            <i class="icon icon-chevron-right"></i>
                        </el-tooltip>
                    </button>
                    <button @click="lastPage()" class="mt-1 pointer pagination-list" :class="{'not-allowed' : temp_total == total}" :disabled ="temp_total == total">
                        <el-tooltip class="item" effect="dark" content="Last Page" placement="top">
                            <i class="icon-chevron-double-right"></i>
                        </el-tooltip>
                    </button>
                </div>
            </div>
        </div>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
        <delete-company-modal v-if="comp_delete" @hideDeleteCompanyModal="hideDeleteCompanyModal" @deleteCompanySuccess="deleteCompanySuccess" modal_name='delete_company_modal' ></delete-company-modal>
        <organization-delete-modal :org_selected="org_selected" v-if="org_delete" @hideDeleteOrganizationModal="hideDeleteOrganizationModal" @deleteOrganizationSuccess="deleteOrganizationSuccess" modal_name='delete_organization_modal' ></organization-delete-modal>
    </div>
</template>
<script>
import organization from '../mixins/organization';
import {HalfCircleSpinner} from 'epic-spinners';
import { SweetModal } from 'sweet-modal-vue'
import moment from 'moment';
import globals from '../globals';
import DeleteCompanyModal from './DeleteCompanyModal.vue';
import OrganizationDeleteModal from './OrganizationDeleteModal.vue';
import { EventBus } from '../eventBus/nav-bar-event';
export default {
    mixins: [organization],
    components: {
        HalfCircleSpinner,
        SweetModal,
        DeleteCompanyModal,
        OrganizationDeleteModal,
        // EventBus
    },
    data() {
        return {
            search_key: '',
            comp_delete: false,
            org_delete: false,
            check_all: false,
            check_one: false,
            companies_open: false,
            warning_msg: '',
            success_msg: '',
            skip:0,
            limit: 10,
            count: 0,
            start: 0,
            pageLimit: [],
            end: 0,
            total: 0,
            skip_temp:0,
            temp_total:0,
            list_view_data: [],
            ajax_call_in_progress: false,
            org_selected: [],
            companies_index: 0,
            hide_edit_create: false,
            company_id: '',
            new_token: '',
            orgName: '',
            orgCreateName:''
            //organisation_id:'',
        }
    },
    methods: {
        moment(date) {
            return moment(date);
        },
        clearSearch(){
            this.search_key = ""
            this.getListViewData();
        },
        setCookie(days) {
            var expires = "";
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                expires = "; expires=" + date.toUTCString();
            }
            document.cookie = "Token" + "=" + (this.new_token || "") + expires + "; path=/";
            setTimeout(() => {
                window.location.reload();
            }, 500);
        },
        showAllCompanies(index) {
            if(this.list_view_data[index].show == true) {
                this.list_view_data[index].show = false;
            } else {
                this.list_view_data[index].show = true;
            }
            this.$forceUpdate();
        },
        hideDeleteCompanyModal() {
            this.comp_delete = false;
            // this.getListViewData();
        },
        async deleteCompanySuccess() {
            let params = {
                id: [this.company_id]
            };
            await this.$http.delete(globals.AUTH_SERVICE + `/company/delete`, {
                data: params
            }).then((response) => {
                if(response.data.status_id == 1) {
                    this.comp_delete = false;
                    this.success_msg = response.data.message;
                    this.$refs.success_modal.open();
                    this.new_token = response.data.token;
                    // if(response.data.token) {
                    //     this.setCookie(7);
                    // }
                    this.getListViewData();
                }
            }).catch((e) => {
                this.comp_delete = false;
                this.warning_msg = e.response.data.reason;
                this.$refs.warning_modal.open();
                this.getListViewData();
            });
        },
        async showDeleteCompany(id) {
            this.company_id = id;
            this.comp_delete = true;
            setTimeout(() => {
                this.$modal.show('delete_company_modal');
            }, 500);
        },
        hideDeleteOrganizationModal() {
            this.org_delete = false;
            // this.getListViewData();
        },
        deleteOrganizationSuccess() {
            this.deleteOrg();
        },
        selectOrg(event, id, index) {
            if(event.target.checked) {
                this.org_selected.push(id);
            } else {
                let index = this.org_selected.indexOf(id);
                if (index > -1) {
                    this.org_selected.splice(index, 1);
                }
            }
            if(this.org_selected.length === this.list_view_data.length) {
                this.check_all = true;
            } else {
                this.check_all = false;
            }
        },
        checkAll(event) {
            if(event.target.checked === true) {
                this.list_view_data.forEach((org, index) => {
                    this.list_view_data[index].checked = true;
                    this.org_selected.push(org.id);
                })
            } else {
                this.list_view_data.forEach((org, index) => {
                    this.list_view_data[index].checked = false;
                })
                this.org_selected = [];
            }
        },
        createOrgCompany(id,orgCreateName) {
            this.$router.push('/organization/createcompanys/'+id +'/'+orgCreateName);
        },
        goToCompanyView(id, orgName) {
            this.$router.push('/company/view/cmpids/'+id +'/'+orgName);
            localStorage.setItem('comp_data_for_menubar', JSON.stringify({company_id: id}));
            EventBus.$emit('comp_api_call_for_menubar')
            EventBus.$emit('comp_api_call_for_menubar_new')
        },
        editOrganization(id) {
            this.$router.push('/organization/edit/'+id)
        },
        previousPage() {
            this.temp_total = 0
            this.skip_temp = 0
            if (this.skip == 0) {
                return;
            }
            this.skip = this.skip - this.limit <= 0 ? 0 : this.skip - this.limit;
            this.getListViewData();
        },
        nextPage() {
            this.skip_temp = this.skip + this.limit > this.total ? this.total : this.skip + this.limit;
            if (this.skip_temp >= this.total) {
                return;
            }
            this.skip = this.skip_temp;
            this.getListViewData();
        },
        firstPage(){
            this.temp_total = 0
            this.skip = 0
            this.limit = this.limit
            this.list_view_data = []
            this.getListViewData();
        },
        lastPage() {
            this.skip_temp = 0
            this.skip = 0
            this.temp_total = this.total
            let no_of_pages = Math.ceil(this.total/this.limit)
            this.skip_temp = (no_of_pages * this.limit) - this.limit
            if (this.skip_temp >= this.total) {
                return;
            }
            this.skip = this.skip_temp
            this.getListViewData();
        },
        
        async getListViewData() {
            this.org_selected = [];
            this.list_view_data = [];
            this.check_all = false;
            this.ajax_call_in_progress = true;
            try {
                let params = {
                    skip: this.skip,
                    limit: this.limit == "" || this.limit == 0 || this.limit < 0 ? 10 : this.limit,
                    search_key: this.search_key
                }
                let response = await this.getHierarchyListView(params);
                if(response.status_id == 1) {
                    this.list_view_data = response.response;
                    this.list_view_data.forEach((org, index) => {
                        this.list_view_data[index].show = false;
                        this.list_view_data[index].checked = false;
                        //this.organisation_id = this.list_view_data[index].id
                    })
                    this.total = response.total_count;
                }
                this.ajax_call_in_progress = false;
            } catch(err) {
                this.ajax_call_in_progress = false;
            }
        },
        showDeleteOrg() {
            this.org_delete = true;
            setTimeout(() => {
                this.$modal.show('delete_organization_modal');
            }, 500);
        },
        async deleteOrg() {
            try {
                let params = {
                    id: this.org_selected.filter(e =>  e)
                };
                let response = await this.deleteOrganization(params);
                if(response.status_id == 1) {
                    this.org_delete = false;
                    this.success_msg = response.message
                    this.new_token = response.token;
                    this.$refs.success_modal.open();
                    // if(response.token) {
                    //     this.setCookie(7);
                    // }
                    setTimeout(() => {
                        this.$refs.success_modal.close();
                        this.getListViewData();
                    }, 2000);
                }
            }
            catch(err) {
                this.org_delete = false;
                this.warning_msg = err;
                this.$refs.warning_modal.open();
            }
        },
        /*Allow Subsidiary Toggle Api*/
        goToProductSettings(id) {
            localStorage.setItem('orgId',id)
            this.$router.push("/organization/productsettings")
        },
        changeToAllow(val) {
            this.subsidiaryToggleSwitch(val);
        },
        async subsidiaryToggleSwitch(org) {
            try {
                let params = {
                    allow_subsidiary_company : org.allow_subsidiary_company,
                    organization_id: org.id
                };
                let response = await this.allowSubsidiaryToggle(params,'single');

                if(response.status_id == 1) {
                    this.success_msg = response.message
                    this.$refs.success_modal.open();
                    setTimeout(() => {
                        this.$refs.success_modal.close();

                    }, 2000);
                }
            }
            catch(err) {
                this.warning_msg = err;
                this.$refs.warning_modal.open();
            }
        }
    },
    mounted() {
        if(this.loggedInUser.has_organization_or_standalone) {
            if(this.loggedInUser.user_has == 'standalone' || this.loggedInUser.user_has == 'consultant') {
                this.$router.push('/company/listview');
            } else if(this.loggedInUser.user_has == 'company') {
                this.$router.push('/company/listview');
            }
        } else {
            this.$router.push('/company/createcompanyororganization');
        }
        this.getListViewData();
        // for (let i = 1; i <= 50; i++) {
        //     i += 9
        //     this.pageLimit.push(i);
        // }
        localStorage.removeItem('comp_data_for_menubar');
    },
    computed: {
        loggedInUser() {
            return this.$store.state.loggedInUser;
        },
        getSkipCount(){
            if(this.total == 0) {
                return 0
            } else {
                let skip_count = this.skip>this.total?this.total:this.skip+1;
                return skip_count;
            }
        },
    },
    created() {
        for (let i = 10; i <= 50; i+=10) {
            this.pageLimit.push(i);
        }
    },
    watch: {
        limit: function(){
            this.getListViewData()
        },
        // search_key: function() {
        //     this.getListViewData();
        // },
        // count: function() {
        //     this.getListViewData();
        // },
    },
}
</script>
<style scoped>
    .multiselect.diginew-multiselect .multiselect__select {
    position: absolute;
    width: 40px;
    height: 40px;
    right: 1px;
    top: -7px !important;
    padding: 4px 8px !important;
    text-align: center;
    }
    .table-view-start {
        background-color: #fff;
    }
    .search-input-field {
        padding: 8px 9.4px 8px 30px;
        border-radius: 18px !important;
        background-color: #fff;
        width: 100%;
    }
    .table-start {
        background-color: #fff;
        padding-top: 0px;
        border-radius: 8px;
        border-top-left-radius: 0;
        overflow-x: auto !important;
    }
    .button-back{
        background: #dcdfe600 !important;
        border: 0px solid #DCDFE6 !important;;
    }
    @media only screen and (max-width: 414px){
        .flex-button{
            flex-direction: column;
        }
        .button-padding{
            margin-top: 5px;
            margin-bottom: 5px;
            align-items: left !important;
        }
    }
    .background-color-company{
        background-color: #ecf1f9;
        border-bottom: 3px solid #fff;
    }
    .margin-company{
        margin-bottom: 10px !important;
    }
    .btn-new-primary{
        height: 32px;
        line-height: 26px;
    }
    .not-allowed{
        cursor: no-drop !important;
    }
</style>